<template>
    <div>
        <div v-if="item.dealers.data.length == 1">
            {{ item.dealers.data[0].name }}
        </div>
        <div v-if="item.dealers.data.length > 1">
            <styled-tooltip
                v-if="item.dealers.data.length > 1"
                position="top">
                <template #content>
                    <ul>
                        <li
                            v-for="(dealer, index) in item.dealers.data"
                            :key="`${index}-${dealer.id}`">
                            {{ dealer.name }}
                        </li>
                    </ul>
                </template>
                <a
                    href="#"
                    @click.prevent="() => false">
                    {{ item.dealers.data.length }}
                </a>
            </styled-tooltip>
        </div>
    </div>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip';
export default {
    name: 'UserDealers',
    components: {
        StyledTooltip
    },
    props: {
        item: {
            type: Object,
            default: () => {},
            required: true
        }
    }
};
</script>

